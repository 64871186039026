import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  ReadOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  PicLeftOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/setting/dashboard" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/setting",
    sidebar: {
      icon: <BarChartOutlined />,
      label: "기본 관리",
    },
    children: [
      {
        exact: true,
        path: "/dashboard",
        sidebar: {
          label: "대시보드",
        },
        component: loadable(() => import("../pages/dashboard/Index")),
      },
      {
        exact: true,
        path: "/banner",
        sidebar: {
          label: "메인 이미지 슬라이더 관리",
        },
        component: loadable(() => import("../pages/banner/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/register",
        component: loadable(() => import("../pages/banner/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/modify/:id",
        component: loadable(() => import("../pages/banner/Modify")),
      },
      {
        exact: true,
        path: "/point",
        sidebar: {
          label: "적립금 지급정책 설정",
        },
        component: loadable(() => import("../pages/setting/Point")),
      },
      {
        exact: true,
        path: "/logo",
        sidebar: {
          label: "서비스 로고 수정",
        },
        component: loadable(() => import("../pages/setting/Logo")),
      },
      {
        exact: true,
        path: "/footer",
        sidebar: {
          label: "푸터 정보 관리",
        },
        component: loadable(() => import("../pages/setting/Footer")),
      },
      {
        exact: true,
        path: "/product/config",
        sidebar: {
          label: "상품 설명 관리",
        },
        component: loadable(() => import("../pages/setting/ProductConfig")),
      },
    ]
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "회원 리스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
    ]
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined />,
      label: "제품 관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "카테고리 목록",
        },
        component: loadable(() => import("../pages/product/category/List")),
      },
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "제품 목록",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/product/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/product/Modify")),
      },
    ]
  },
  {
    exact: true,
    path: "/order",
    level: 3,
    sidebar: {
      icon: <ShoppingCartOutlined />,
      label: "주문 관리",
    },
    children: [
      {
        exact: true,
        path: "/index",
        sidebar: {
          label: "주문 목록",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/order/Detail")),
      },
      {
        exact: true,
        path: "/cancel",
        sidebar: {
          label: "취소/반품 관리",
        },
        component: loadable(() => import("../pages/order/Cancel")),
      },
    ],
  },
  {
    exact: true,
    path: "/notice",
    sidebar: {
      icon: <PicLeftOutlined />,
      label: "정보전달 게시판 관리",
    },
    children: [
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "정보전달 게시글 리스트",
        },
        component: loadable(() => import("../pages/notice/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/notice/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/notice/Modify")),
      },
    ]
  }
];
